import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Hero from "../components/panels/Hero";
import Video from "../components/non-panels/react-modal-video/normal-video";
import { Col } from "react-bootstrap";
import PanelFoundation from "../components/panels/PanelFoundation";

// styled
import s from "./explainer-videos.module.scss";

// utils
import LinkButton from "../utils/controls/LinkButton";

export default function Videos({
  location,
  data: { strapiPnExplainerVideos, allStrapiPostExplainerVideos }
}) {
  const { SEO: seo, hero } = strapiPnExplainerVideos;

  const renderVideos = () => {
    const publishedVideos = allStrapiPostExplainerVideos.nodes.filter(
      item => item.explainerVideos.publishVideo === true
    );

    return publishedVideos.map((item, index) => {
      if (index % 2 === 0) {
        return (
          <PanelFoundation rowStyle={{ marginBottom: "4rem" }}>
            <Col lg={7} md={12}>
              <Video
                channel={"vimeo"}
                ratio="16:9"
                autoplay={1}
                allowFullScreen={true}
                videoId={item.explainerVideos.videoId}
              />
            </Col>
            <Col lg={5} md={12}>
              <div className={s.videoDescription}>
                <h1 className={`text-primary mb-5`}>
                  {item.explainerVideos.videoTitle}
                </h1>
                <p className={`text-justify text-muted mb-5`}>
                  {item.explainerVideos.videoDescription}
                </p>
              </div>
            </Col>
          </PanelFoundation>
        );
      } else {
        return (
          <PanelFoundation rowStyle={{ marginBottom: "4rem" }}>
            <Col lg={5} md={12}>
              <div className={s.videoDescription}>
                <h1 className={`text-primary mb-5`}>
                  {item.explainerVideos.videoTitle}
                </h1>
                <p className={`text-justify text-muted mb-5`}>
                  {item.explainerVideos.videoDescription}
                </p>
              </div>
            </Col>
            <Col lg={7} md={12}>
              <Video
                channel={"vimeo"}
                ratio="16:9"
                autoplay={1}
                allowFullScreen={true}
                videoId={item.explainerVideos.videoId}
              />
            </Col>
          </PanelFoundation>
        );
      }
    });
  };

  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | Insights`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_hero_image.childImageSharp.fixed}
      />
      <div className={`container-fluid mt-4`}>
        <div className={`container`}>
          <div style={{ display: "flex", marginBottom: "1.5rem" }}>
            <LinkButton
              to={`/explainer-videos`}
              backgroundColorCondition={
                hero[0].main_heading === "Explainer Videos"
              }
              buttonStyles={s.sectionButton}
              innerText="Explainer Videos"
            />
            <LinkButton
              to={`/webinar`}
              backgroundColorCondition={hero[0].main_heading === "Webinars"}
              buttonStyles={s.sectionButton}
              innerText="Webinars"
            />
            {/* <LinkButton
              to={`/media`}
              backgroundColorCondition={hero[0].main_heading === "Media"}
              buttonStyles={s.sectionButton}
              innerText="Media"
            /> */}
          </div>
        </div>
      </div>
      {renderVideos()}
    </>
  );
}

export const explainerVideos = graphql`
  query explainerVideos {
    strapiPnExplainerVideos {
      SEO {
        description
        tab_title
      }
      hero {
        main_heading
        main_subheading
        about_hero_image: insight_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    allStrapiPostExplainerVideos {
      nodes {
        explainerVideos {
          id
          publishVideo
          videoDescription
          videoId
          videoTitle
        }
      }
    }
  }
`;
